<template>
  <ContainerWithMenu :loading="loading">
    <div class="page-content hide-print">
      <div class="row">
        <div class="col-xs-9">
          <h1 class="h2 margin-bottom-20">Prenotazione #<span id="reservation_id">{{this.reservation_id}}</span>
              <span style="margin-left: 10px"  v-if="!reservation.check_in && reservation.state === Keys.RESERVATION_STATES.COMPLETED && reservation.channel !== Keys.RESERVATION_CHANNEL.OFFLINE " class="label label-primary" >
                Da Convalidare
            </span>
              <span v-else class="label" style="margin-left: 10px" :class="LabelClassHelper.getLabelClassReservationState(reservation.state)">
                  {{StringsHelper.getReservationState(reservation.state)}}
              </span>


              <span v-if="reservation.chebck_in" style="margin-left: 10px" class="label label-warning">Convalidato da {{StringsHelper.getCreatorSimple(reservation.check_in_creator)}} il {{Utils.getDateStringFromISO(reservation.check_in_date)}}</span>
          </h1>
        </div>
        <div class="col-xs-3 text-right">
          <a class="btn btn-default btn-sm" @click="printTicket()"><span class="glyphicon glyphicon-print" aria-hidden="true"></span> Stampa biglietto</a>
        </div>
      </div>
      <p class="margin-bottom-20">Ticket Numero <span class="label label-primary" id="ticket_barcode">{{reservation.ticket_code}}</span></p>
      <div class="row">
        <div class="col-xs-6">
          <div class="booking-section">
            <h4>Sito</h4>
            <div id="product_list" v-for="product in reservation.product_list" :key="product"><p>{{StringsHelper.getProduct(product)}}</p></div>
          </div>
          <div class="booking-section">
            <h4 style="margin-bottom:20px;" v-if="this.reservation.channel === Keys.RESERVATION_CHANNEL.OFFLINE">Data e Orario</h4>
            <h4 v-else style="margin-bottom:20px;">Data</h4>
            <div class="row section-editable" v-show="updatingReservation">
              <div class="col-xs-12">
                <div class="form-group has-feedback">
                  <label class="control-label" for="reservation_date_edit">Data e Orario</label>
                    <Datetime
                            type="datetime"
                            :value-zone="TIMEZONE_DEFAULT"
                            :zone="TIMEZONE_DEFAULT"
                            v-model="reservation.date"
                            input-class="form-control"
                    ></Datetime>
                  <span class="glyphicon glyphicon-calendar form-control-feedback" aria-hidden="true"></span>
                </div>
              </div>

            </div>
            <div class="row section-not-editable" v-show="!updatingReservation">
              <div class="col-xs-6">
                <div class="form-group has-feedback">
                  <label v-if="reservation.channel === Keys.RESERVATION_CHANNEL.OFFLINE" class="control-label" for="reservation_date">Data e Ora</label>
                  <label v-else class="control-label" for="reservation_date">Data</label>
                  <p class="margin-0"><span class="glyphicon glyphicon-calendar" aria-hidden="true"></span> <span v-if="reservation.channel === Keys.RESERVATION_CHANNEL.OFFLINE">{{Utils.getDateStringFromISO(reservation.date)}}</span> <span v-else>{{Utils.getShortDateStringFromISO(reservation.date)}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="booking-section">
            <h4>Informazioni Cliente</h4>
            <div class="row section-editable" v-show="updatingReservation">
              <div class="col-xs-12 margin-bottom-15">
                <div class="form-group">
                  <label for="customer_nationality_edit">Nazione*</label>
                    <SelectCountry v-if="reservation.customer_nationality" @country-changed="countryChanged" :default-country="reservation.customer_nationality"></SelectCountry>
                </div>
              </div>
              <div v-bind:class="[isInvoiceType() ? 'col-xs-12' : 'col-xs-6']">
                <div class="form-group" >
                  <label for="customer_first_name_edit">Nome o Azienda</label>
                  <input type="text" class="form-control" id="customer_first_name_edit" v-model="reservation.customer_first_name">
                </div>
              </div>
              <div class="col-xs-6" v-if="!isInvoiceType()">
                <div class="form-group">
                  <label for="customer_last_name_edit">Cognome</label>
                  <input type="text" class="form-control" id="customer_last_name_edit" v-model="reservation.customer_last_name">
                </div>
              </div>
              <div class="col-xs-12 margin-top-20 cancel_reason_section" v-show="reservation.state === Keys.RESERVATION_STATES.CANCELED">
                <div class="form-group">
                  <label for="cancel_reason_edit">Motivazione annullamento*</label>
                  <textarea class="form-control" id="cancel_reason_edit" rows="3" maxlength="255" v-model="reservation.cancel_reason"></textarea>
                </div>
              </div>
                <div class="col-xs-12 margin-top-20">
                    <div class="form-group">
                        <label for="customer_email_edit">Email</label>
                        <input type="email" class="form-control" id="customer_email_edit" v-model="reservation.customer_email">
                    </div>
                    <div class="form-group">
                        <label for="customer_phone_edit">Telefono</label>
                        <VuePhoneNumberInput
                                @update="updatePhoneNumber"
                                no-validator-state
                                default-country-code="IT"
                                v-model="reservation.customer_phone"
                                :translations="Utils.getTranslationsPhoneField()"
                                id="phone"></VuePhoneNumberInput>
                    </div>
                    <div class="form-group" v-if="isInvoiceType()">
                        <label for="customer_vat_number_edit">Partita IVA</label>
                        <input type="text" class="form-control" id="customer_vat_number_edit" v-model="reservation.customer_vat_number">
                    </div>
                    <div class="form-group" v-if="isInvoiceType()">
                        <label for="customer_pec_edit">PEC</label>
                        <input type="email" class="form-control" id="customer_pec_edit" v-model="reservation.customer_pec">
                    </div>
                    <div class="form-group" v-if="isInvoiceType()">
                        <label for="customer_address_edit">Indirizzo</label>
                        <input type="text" class="form-control" id="customer_address_edit" v-model="reservation.customer_address">
                    </div>
                    <div class="form-group" v-if="isInvoiceType()">
                        <label for="customer_recipient_code_edit">Codice Destinatario</label>
                        <input type="text" class="form-control" id="customer_recipient_code_edit" v-model="reservation.customer_recipient_code">
                    </div>
                </div>
            </div>
            <div class="row section-not-editable" v-show="!updatingReservation">
              <div class="col-xs-12">
                <div class="form-group">
                  <label for="customer_nationality">Nazione*</label>
                  <p id="customer_nationality">{{this.COUNTRIES[reservation.customer_nationality]}}</p>
                </div>
              </div>
              <div class="col-xs-6">
                <div class="form-group">
                  <label for="customer_first_name">Nome</label>
                  <p v-if="reservation.customer_first_name != null" id="customer_first_name">{{reservation.customer_first_name}}</p>
                  <p v-else>NA</p>
                </div>
                <div class="form-group">
                  <label for="customer_email">Email</label>
                  <p v-if="reservation.customer_email != null" id="customer_email">{{reservation.customer_email}}</p>
                  <p v-else>NA</p>
                </div>
                  <div class="form-group" v-if="isInvoiceType()">
                      <label for="customer_last_name">Partita IVA</label>
                      <p v-if="reservation.customer_vat_number != null" id="vat_number">{{reservation.customer_vat_number}}</p>
                      <p v-else>NA</p>
                  </div>
                  <div class="form-group" v-if="isInvoiceType()">
                      <label for="customer_phone">PEC</label>
                      <p v-if="reservation.customer_pec != null" id="pec">{{reservation.customer_pec}}</p>
                      <p v-else>NA</p>
                  </div>
              </div>
              <div class="col-xs-6" >
                <div class="form-group" v-if="!isInvoiceType()">
                  <label for="customer_last_name">Cognome</label>
                  <p v-if="reservation.customer_last_name != null" id="customer_last_name">{{reservation.customer_last_name}}</p>
                  <p v-else>NA</p>
                </div>
                <div class="form-group">
                  <label for="customer_phone">Telefono</label>
                  <p v-if="reservation.customer_phone != null" id="customer_phone">{{reservation.customer_phone}}</p>
                  <p v-else>NA</p>
                </div>
                  <div class="form-group" v-if="isInvoiceType()">
                      <label for="customer_phone">Indirizzo</label>
                      <p v-if="reservation.customer_address != null" id="address">{{reservation.customer_address}}</p>
                      <p v-else>NA</p>
                  </div>
                  <div class="form-group" v-if="isInvoiceType()">
                      <label for="customer_phone">Codice Destinatario</label>
                      <p v-if="reservation.customer_recipient_code != null" id="customer_recipient_code">{{reservation.customer_recipient_code}}</p>
                      <p v-else>NA</p>
                  </div>
              </div>
              <div class="col-xs-12 cancel_reason_section"  v-show="reservation.state === Keys.RESERVATION_STATES.CANCELED">
                <div class="form-group">
                  <label for="cancel_reason">Motivazione annullamento</label>
                  <p id="cancel_reason">{{StringsHelper.getStringOrNA(reservation.cancel_reason)}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="panel panel-default">
            <div class="panel-heading">Cassa</div>
            <div class="panel-body">
              <CashSummaryTable v-if="reservation" :estimate-reservation="reservation"></CashSummaryTable>
            </div>
          </div>
            <div class="panel panel-default">
                <div class="panel-heading">Tipo Documento</div>
                <div class="panel-body">
                    <div class="section-editable" v-show="updatingReservation">
                        <select class="form-control rate_option" v-model="reservation.document_type" @change="onChange">
                            <option v-for="(type, index) in documentTypeOptions" :value="type.value" :key="index" >
                                {{type.name}}
                            </option>
                        </select>
                        <div v-if="reservation.document_type === Keys.DOCUMENT_TYPES.INVOICE" style="margin-top: 20px">
                            <span>N. Fattura</span>

                            <input type="number" class="form-control" id="fname" v-model="reservation.invoice_number">
                        </div>
                    </div>
                    <p>
                        <span class="section-not-editable" v-show="!updatingReservation">{{StringsHelper.getDocumentType(reservation.document_type)}}</span>
                        <span v-if="reservation.document_type === Keys.DOCUMENT_TYPES.INVOICE" class="section-not-editable" v-show="!updatingReservation"> N. {{reservation.creator.prefix_invoice}}{{reservation.invoice_number}}</span>
                    </p>
                </div>
            </div>
          <div class="panel panel-default">
            <div class="panel-heading">Pagamento</div>
            <div class="panel-body">
              <p class="section-editable" v-show="updatingReservation">
                <label for="payment_mode_edit">Metodo di pagamento</label>
                <select id="payment_mode_edit" class="form-control" v-model.number="reservation.payment_mode">
                    <option v-for="payment in paymentOptions" :key="payment.value" :value="payment.value">{{payment.name}}</option>
                </select>
              </p>
              <p>
                <span class="section-not-editable" v-show="!updatingReservation" id="payment_mode">{{StringsHelper.getPaymentMode(reservation.payment_mode)}}</span>  il <span id="created_date">{{Utils.getShortDateStringFromISO(reservation.purchase_date)}}</span> alle ore <span id="created_hour">{{Utils.getHourStringFromISO(reservation.purchase_date)}}</span>.</p>
              <p v-if="reservation.creator">Prenotazione eseguita da <u>{{reservation.creator.display_name}}</u>.</p>
                <p v-else>Prenotazione eseguita <u>{{StringsHelper.getChannel(reservation.channel)}}</u> da  <u>{{reservation.customer_first_name}} {{reservation.customer_last_name}}</u>.</p>
            </div>
          </div>
          <div class="row" v-if="reservation.channel === Keys.RESERVATION_CHANNEL.OFFLINE">
            <div class="col-xs-6 text-center">
              <a id="edit-reservation-btn" v-show="!updatingReservation" class="btn btn-default btn-block" @click.prevent.stop="updatingReservation=true"><span class="glyphicon glyphicon-edit" aria-hidden="true"></span> Modifica Prenotazione</a>
              <a id="save-reservation-btn" v-show="updatingReservation" class="btn btn-success btn-block margin-0" @click.prevent.stop="updateReservation"><span class="glyphicon glyphicon-check" aria-hidden="true"></span> Salva Modifiche</a>
            </div>
            <div class="col-xs-6 text-center" id="cancel-reservation-section" v-if="reservation.state !== Keys.RESERVATION_STATES.CANCELED">
              <a class="btn btn-danger btn-block" href="javascript:;" data-toggle="modal" data-target="#CancelReservationModal"  @click.prevent.stop="visibleCancelReservationModal=true"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span> Annulla Prenotazione</a>
            </div>
          </div>
        </div>
      </div>
    </div>
      <CancelReservationModal :visible="visibleCancelReservationModal"
                              @close-cancel-reservation-modal="visibleCancelReservationModal=false"
                              @positive-btn-cancel-reservation-modal="cancelReservation"
      ></CancelReservationModal>

      <Ticket id="section-to-print" style="display: none" :reservation="reservation"></Ticket>
      <GenericChooseModal code="1" title="Informazione" message="Sei sicuro di voler stampare un biglietto gia' convalidato ?" :hide-negative-button="false" positive-button-text="Stampa lo stesso" :visible="visibleModalSurePrint" negative-button-text="Annulla" @negative-click-generic-modal1="visibleModalSurePrint = false" @positive-click-generic-modal1="positivePrintClick" @close-generic-modal1="visibleModalSurePrint = false"/>
      <GenericChooseModal code="3" title="Informazione" :danger-message="true" message="Numero Fattura già esistente" :hide-negative-button="true" positive-button-text="Va bene" :visible="visibleModalSameInvoiceNumber" @positive-click-generic-modal3="visibleModalSameInvoiceNumber = false" @close-generic-modal3="visibleModalSameInvoiceNumber = false"/>
      <GenericChooseModal code="4" title="Informazione" :danger-message="true" message="Ricordati di compilare tra i dati cliente: Partita Iva, PEC, Indirizzo" :hide-negative-button="true" positive-button-text="Va bene" :visible="visibleModalInvoiceParameterMisses" @positive-click-generic-modal4="visibleModalInvoiceParameterMisses = false" @close-generic-modal4="visibleModalInvoiceParameterMisses = false"/>
  </ContainerWithMenu>
</template>

<script>
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css'
  import ContainerWithMenu from "../components/container/ContainerWithMenu";
     import {
        cancelReservation,
        checkInReservation,
        getInvoiceNumber,
        getReservationDetail,
        updateReservation
    } from "../api";
  import baseMixin from "../common/baseMixin";
  import {COUNTRIES} from "../common/countries";
  import CashSummaryTable from "../components/Booking/CashSummaryTable";
  import SelectCountry from "../components/common/SelectCountry";
  import {TIMEZONE_DEFAULT} from "../common/constants";
  import {Datetime} from "vue-datetime";
  import 'vue-datetime/dist/vue-datetime.css'
    import CancelReservationModal from "../components/modal/CancelReservationModal";
    import Ticket from "./Ticket";
    import GenericChooseModal from "../components/modal/GenericChooseModal";

  export default {
    name: "ReservationDetail",
    title: "Prenotazione nel dettaglio",
    components: {
        Ticket,
        CashSummaryTable, ContainerWithMenu, SelectCountry, Datetime, VuePhoneNumberInput, CancelReservationModal,GenericChooseModal},
    mixins: [baseMixin],
    data: () => {
      return {
          loading: false,
          reservation: {},
          reservation_id: null,
          documentTypeOptions: [],
          COUNTRIES: COUNTRIES,
          updatingReservation: false,
          TIMEZONE_DEFAULT: TIMEZONE_DEFAULT,
          phone_number_international: null,
          paymentOptions: [],
          visibleCancelReservationModal: false,
          visibleModalSurePrint: false,
          visibleModalSameInvoiceNumber: false,
          visibleModalInvoiceParameterMisses: false
      }
    },
    mounted(){
        this.paymentOptions = this.Keys.PAYMENT_MODE_WITHOUT_CREDIT_CARD_OPTIONS();
        this.reservation_id = this.$route.params.reservation_id;
        this.getReservationDetail(this.reservation_id);
        this.documentTypeOptions = this.Keys.DOCUMENT_TYPE_OPTIONS();
    },
    methods: {
        isInvoiceType(){
            return this.reservation.document_type === this.Keys.DOCUMENT_TYPES.INVOICE
        },
        positivePrintClick(){
            this.visibleModalSurePrint = false
            window.print()
        },
        async printTicket(){
            if(this.reservation.channel !== this.Keys.RESERVATION_CHANNEL.OFFLINE && !this.reservation.check_in){
                this.loading = true;
                try{
                    this.reservation = await checkInReservation(this.reservation_id)
                    this.loading = false;
                    window.print()
                }catch (e){
                    this.loading = false;
                }
            }
            else if(this.reservation.channel !== this.Keys.RESERVATION_CHANNEL.OFFLINE && this.reservation.check_in){
                this.visibleModalSurePrint = true
            }
            else{
                window.print()
            }
        },
        updatePhoneNumber(result) {
            this.phone_number_international = result.formatInternational;
        },
        countryChanged(newCountry) {
            this.reservation.customer_nationality= newCountry;
        },
        async updateReservation() {
            this.loading = true;
            try {
                this.reservation = await updateReservation(
                    this.reservation_id,
                    this.reservation.customer_first_name,
                    this.reservation.customer_last_name,
                    this.reservation.customer_email,
                    this.phone_number_international,
                    this.reservation.customer_nationality,
                    this.reservation.payment_mode,
                    this.reservation.date,
                    this.reservation.document_type,
                    this.reservation.invoice_number,
                    this.reservation.customer_vat_number,
                    this.reservation.customer_pec,
                    this.reservation.customer_address,
                    this.reservation.customer_recipient_code,
                    this.reservation.cancel_reason
                );
                this.$route.params.successMessage = "Prenotazione aggiornata con successo";
                this.updatingReservation = false;
                this.loading = false;
            } catch (e) {
                this.loading = false;
                if(e.response.data.code === "same_invoice_found"){
                    this.visibleModalSameInvoiceNumber = true
                    this.$route.params.successMessage = null
                    this.$route.params.errorMessage = null
                }
                else if(e.response.data.code === "invoice_parameters_misses"){
                    this.visibleModalInvoiceParameterMisses = true
                    this.$route.params.successMessage = null
                    this.$route.params.errorMessage = null
                }
            }
        },
        async cancelReservation(cancelReason) {
            this.loading = true;
            try {
                this.reservation = await cancelReservation(
                    this.reservation_id,
                    cancelReason
                );

                this.reservation.state = this.Keys.RESERVATION_STATES.CANCELED;

                this.$route.params.successMessage = "Prenotazione annullata con successo";
                this.updatingReservation = false;
                this.loading = false;
            } catch (e) {
                this.loading = false;
            }
            this.visibleCancelReservationModal = false
        },
      async getReservationDetail(id) {
        this.loading = true;
        try {
          this.reservation = await getReservationDetail(id);
          this.loading = false;
        } catch (e) {
          this.loading = false;
          console.log(e)
        }
      },
        async getInvoiceNumber() {
            this.loading = true;
            try {
                let result = await getInvoiceNumber();
                this.reservation.invoice_number = result.invoice_number
                console.log(result.invoice_number)
                this.loading = false;
            } catch (e) {
                this.loading = false;
            }
        },
        onChange() {
            if (this.reservation.document_type === this.Keys.DOCUMENT_TYPES.INVOICE) {
                this.getInvoiceNumber()
            }
        },
    }
  }
</script>

<style scoped>
    @media print {
        body * {
            visibility: hidden;
        }
        #section-to-print {
            display: block!important;
        }
        #section-to-print, #section-to-print * {
            visibility: visible;
        }
        #section-to-print {
            position: absolute;
            left: 0;
            top: 0;
            margin: 0;
            padding: 0;
        }
    }
</style>
